
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/course/List.vue";

import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { CourseActions, CourseGetters } from "@/store/vm/course/enums";

interface ComponentData {
  courseIdModal: number | boolean;
}
export default defineComponent({
  name: "course",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
  },
  data(): ComponentData {
    return {
      courseIdModal: false,
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Chương trình học", ["Đào tạo"]);
      MenuComponent.reinitialization();
    });

    const onDel = (courseId) => {
      const detail = store.getters[CourseGetters.GET_BY_ID](courseId);

      Swal.fire({
        title: "Bạn muốn xóa chương trình học?",
        text: detail.title,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(courseId);
          console.log("resDel");
          console.log(resDel);
        }
      });
    };

    const actionDelete = async (courseId) => {
      return store.dispatch(CourseActions.DELETE, courseId).catch((e) => {
        console.log(e);
      });
    };

    return {
      onDel,
    };
  },
});
